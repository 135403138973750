body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #000000;
  color: #ffffff;
  overflow-x: hidden;
}

/* button,
h1,h2,h3,h4,h5,h6{
  font-family: "Inter", sans-serif !important;
} */

a {
  color: #02ddf3;
  text-decoration: none;
}

a:hover {
  color: #02ddf3;
  text-decoration: underline;
}

.h2tag {
  font-weight: 600;
  font-size: 42px;
  background: -webkit-linear-gradient(38deg, #69b12a 38%, #5799de 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paratag {
  font-size: 18px;
  font-family: 'Figtree', sans-serif;
  font-weight: 300;
}

.h5tag {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.head {
  max-width: 75%;
  text-align: center;
  margin: 0 auto 15px;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #0c2512;
}

.brand_logo {
  width: 165px;
}

.menu_show {
  background: #363cee;
  display: inline-block !important;
  position: fixed;
  right: -100%;
  height: 100%;
  z-index: 10;
  top: 0;
  transition: .5s;
}

.main_navbar {
  background-color: #00162400 !important;
  /* border-bottom: 1px solid #0c4751; */
  z-index: 10;
  transition: transform 0.3s;
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: -40px;
  background-color: #050505 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 40%);
  z-index: 999;
  transform: translateY(40px);
  justify-content: flex-end;
}

.main_navbar .navbar-toggler-icon {
  display: none;
}

.main_navbar .navbar-toggler {
  box-shadow: none !important;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.navbar_right li a {
  color: #5c7086;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.navbar_right li a:hover,
.navbar_right li a.active {
  color: #fff !important;
}

.navbar_right li a.active::after,
.navbar_right li a:hover::after {
  content: "";
  background: #02cce1;
  width: 90%;
  height: 1px;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.navbar_right button.primary_btn {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 25px;
  text-transform: none;
  font-family: 'Figtree', sans-serif;
}

.navbar_right .primary_btn:after,
.navbar_right .primary_btn:before {
  content: " ";
  /* width: 30px;
  height: 30px; */
}

.page_header {
  position: relative;
  padding: 250px 0 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url("../src/assets/images/banbg2.png") no-repeat center;
  background-size: 100% 100%;
  background-size: cover;
  margin-top: -96px;
  overflow: visible !important;
  /* height: 1072px; */

}


.page_header>.container>.row:nth-child(1) {
  z-index: 10;
  position: relative
}

.banner {
  text-align: center;
}

.banner h1 {
  font-size: 64px;
  background: -webkit-linear-gradient(38deg, #69b12a 38%, #5799de 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.banner .paratag {
  max-width: 81%;
  margin: 0 auto 30px;
  font-weight: 500;
}


.timer_panel {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px 0 20px;
}

.timer_panel .num {
  background-size: contain;
  width: 219px;
  height: 127px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.timer_panel .num1 {
  background: url("../src/assets/images/bannum1.png") no-repeat center;
}

.timer_panel .num2 {
  background: url("../src/assets/images/bannum2.png") no-repeat center;
}

.timer_panel .num3 {
  background: url("../src/assets/images/bannum3.png") no-repeat center;
}

.timer_panel .num4 {
  background: url("../src/assets/images/bannum4.png") no-repeat center;
}

.timer_time {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
}

span.timer_label {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}


.timer_panel>span {
  margin: 5px -30px 0 0;
}


.presale_box {
  padding: 40px;
  padding-top: 20px;
  text-align: center;
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(90deg, #07294e, #000, #000, #385e16) border-box;
  border-radius: 20px;
  border: 2px solid transparent;
  position: relative;
  z-index: 1;
  margin-top: -100px;
}

.presale_box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: #000;
  width: 36%;
  height: 4px;
  top: -2px;
  margin: 0 auto;
}

.presale_box h2 {
  font-size: 24px;
  font-weight: 600;
  background: -webkit-linear-gradient(38deg, #69b12a 38%, #5799de 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  background-color: #000;
  display: inline-block;
}

.presale_box p {
  font-size: 18px;
}

.table_sec h2{
  background: -webkit-linear-gradient(310deg, #69b12a 38%, #5799de 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.table_sec .table-responsive{
border: 2px solid #ff000000;
border-radius: 20px;
background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #0d4e94, #000, #69b12a) border-box;
padding: 20px;
}

.table_sec table{
    color: #fff;
    border-color: black !important;
    white-space: nowrap;
}

.table_sec h5{
  font-size: 18px;
  color: #79e917;
}
.table_sec h5 img{
  width: 60px;
  margin: 0 10px;
}
.table_sec h5 img.certik{
  width: 39px;
}
.table_sec .box{
  border: 2px solid #ff000000;
  border-radius: 20px;
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #434343, #000, #434343) border-box;
  padding: 20px;
  width: 100%;
  margin: 15px 0;
  text-align: center;
}
.table_sec .box:hover{
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #0d4e94, #000, #69b12a) border-box;
}
.table_sec ul{
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.table_sec ul li{
  margin: 10px 0;
  display: inline-flex;
  width: 33.333333%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.table_sec .note {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}
.table_sec .note img{
  width: 45px;
  border-radius: 50%;
  margin: 0 8px;
}
.table_sec .note span{
  color: #79e917;
}
.table_sec ul li img{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-bottom: 15px;
  /* margin-right: 15px; */
}
.table_sec ul li img.hit{
  background: #fff;
}
.table_sec .box h5{
 color: #fff;
}
.table_sec .h5t{
  background: -webkit-linear-gradient(310deg, #69b12a 38%, #5799de 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.stake_table table th{
  width: 50%;
}

.buy_token {
  text-align: center;
  background: url("../src/assets/images/buytoken_bg.png") no-repeat center;
  background-size: cover;
  padding-top: 100px !important;
}

.buy_token img {
  margin-bottom: 35px;
}

.buy_token h2 {
  color: #fff;
  background: none;
  -webkit-text-fill-color: unset;
}

.buy_token .paratag {
  max-width: 80%;
  margin: 0 auto 15px;
}


section {
  padding: 60px 0;
  overflow: hidden;
}


.features .box {
  padding: 35px;
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #434343, #000, #434343) border-box;
  border-radius: 20px;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
}

.features .box::before {
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  bottom: 0;
  right: 0;
  border-radius: 50% 0 0%;
  filter: blur(61px);
}

.features .box::after {
  content: "";
  position: absolute;
  width: 30%;
  height: 30%;
  bottom: 0;
  left: 0;
  border-radius: 0% 50% 0%;
  filter: blur(61px);
}

.features .box p {
  opacity: .4;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 300;
}

.features .box h5 {
  color: #fff;
  opacity: .4;
  display: inline-block;
  font-size: 22px;
}

.features .outbox {
  position: relative;
  margin: 30px 0;
}

.features .img_box {
  position: relative;
  margin-bottom: 15px;
  background: radial-gradient(#000000 20%, #676767 60%);
  width: 78px;
  height: 78px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  position: absolute;
  top: -40px;
  z-index: 100;
  left: 40px;
}

.features .img_box img {
  opacity: .7;
}

.features .outbox .box {
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #0d4e94, #000, #69b12a) border-box;
}

.features.features_hvr .outbox:hover .box {
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #434343, #000, #434343) border-box;
}

.features .outbox .box::before {
  background: #69b12a;
}

.features .outbox .box::after {
  background: #1d5ea3;
 }
.features.features_hvr .outbox:hover .box::before {
  background: none;
}

.features.features_hvr .outbox:hover .box::after {
  background: none;
}

.features .outbox .img_box {
  border: 1px solid #63a727;
  background: radial-gradient(#000000 20%, #0d1c02 60%);
}
.features.features_hvr .outbox:hover .img_box {
  background: radial-gradient(#000000 20%, #676767 60%);
  border: 1px solid transparent;
}

.features .outbox .img_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/img_glowing.png") no-repeat center;
  width: 158px;
  height: 155px;
  background-size: contain;
}
.features.features_hvr .outbox:hover .img_box::before {
  background: none;
}

.features .outbox .img_box img {
  opacity: 1;
}
.features.features_hvr .outbox:hover .img_box img {
  opacity: .7;
}
.features .box p {
  opacity: 1;
}
.features.features_hvr .box:hover p {
  opacity: .4;
}

.features .box .h5tag {
  color: #fff;
  opacity: 1;
  background: -webkit-linear-gradient(38deg, #69b12a 38%, #5799de 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features.features_hvr .box:hover .h5tag {
  color: #fff;
  opacity: .4;
  background:#fff ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.featureschg1 .outbox {
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #434343, #000, #434343) border-box;
  border-radius: 20px;
  border: 1px solid transparent;
  width: 100%;
}

.featureschg1 .box {
  height: 100%;
  background: none;
}

.featureschg1 .box ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.featureschg1 .box ul p {
  margin: 0;
}

.featureschg2 {
  padding: 80px 0 !important;
}

.featureschg2 .outbox {
  background: none;
}

.featureschg2 .inbox {
  margin: 20px;
  position: relative;
}

.featureschg2 .inbox::before {
  content: "";
  position: absolute;
  width: 56%;
  height: 100%;
  right: -11px;
  top: 11px;
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #0d4e94, #000, #69b12a) border-box;
  border: 1px solid transparent;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.featureschg2 .outbox .box {
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #0d4e94, #000, #69b12a) border-box;
}

.featureschg2 .outbox .box::before {
  background: #69b12a;
  top: 0;
}

.featureschg2 .outbox .box::after {
  background: #1d5ea3;
}

.featureschg2 .box .h5tag {
  color: #fff;
  opacity: 1;
  background: -webkit-linear-gradient(38deg, #69b12a 38%, #5799de 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featureschg2 .box p {
  opacity: 1;
}

.featureschg2 .mar {
  margin-top: 180px;
}

.featureschg2 .box {
  padding: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
}

.featureschg2 .slick-slide>div:focus-visible {
  outline: 0 !important;
}

.featureschg2 .slick-arrow {
  border: 1px solid #0b4583 !important;
  background: radial-gradient(#000000 20%, #000717 60%) !important;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  bottom: -78px;
  top: unset;
}

.featureschg2 .slick-prev:before,
.featureschg2 .slick-next:before {
  background: url("./assets/images/leftarw.png") no-repeat center;
  width: 26px;
  height: 19px;
  font-size: 0;
  display: block;
}

.featureschg2 .slick-next:before {
  background: url("./assets/images/rightarw.png") no-repeat center;
}

.featureschg2 .slick-prev::after,
.featureschg2 .slick-next::after {
  content: "";
  position: absolute;
  background: url("./assets/images/img_glowing_chg.png") no-repeat center;
  width: 158px;
  height: 155px;
  background-size: contain;
}

.featureschg2 .slick-next {
  top: 0;
}






.grid_sec {
  background: url("./assets/images/grid_bg.png") no-repeat center;
  background-size: cover;
  /* background-size: 100% 100%;
  background-size: 100% 160%; */
}

.grid_sec .head {
  max-width: 35%;
}

.grid_sec .box {
  margin: 20px 0;
}

.grid_sec .box p {
  font-size: 18px;
  color: #79e917;
  margin-bottom: 0;
  font-weight: 300 !important;
}

.grid_sec .box .paratag {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}


.features_chg {
  background: url("./assets/images/featurechg_bg.png") no-repeat center;
  background-size: 100% 110%;
  padding-top: 100px !important;
}

.features_chg .box {
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box,
    linear-gradient(132deg, #000000 15%, #000000 25%, #000000 55%, #0c498a 35%, #000 87%) border-box !important;
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box,
    linear-gradient(132deg, #000000 15%, #000000 25%, #000000 35%, #0c498a 70%, #000 87%) border-box !important;
  padding-bottom: 35px;
  z-index: 1;
}

.features_chg .box:before {
  background: #020d18 !important;
  bottom: unset;
  top: 0;
  z-index: -1;
}

.features_chg .box::after {
  background: #0f3e72 !important;
  z-index: -1;
}

.features_chg .outbox .img_box {
  border: 1px solid #0b4583 !important;
  background: radial-gradient(#000000 20%, #000717 60%) !important;
}

.features_chg .outbox .img_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/img_glowing_chg.png") no-repeat center !important;
  width: 158px;
  height: 155px;
  background-size: contain;
}

.features_chg .outbox .img_box img {
  opacity: 1;
}

.features_chg .box p {
  opacity: 1;
}

.features_chg .box h5 {
  color: #fff !important;
  opacity: 1;
  font-weight: 500 !important;
}

.features_chg .box h5.h5tag {
  background: -webkit-linear-gradient(250deg, #31b8a1 38%, #5799de 44%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0 !important;
}

.features_chg .outbox {
  margin: 40px 0;
  width: 100%;
}

.features_chg .box .above {
  margin-bottom: 50px;
}

.features_chg .col-md-6:nth-child(1) .above,
.features_chg .col-md-6:nth-last-child(1) .above {
  margin-bottom: 100px;
}

.features_chg .col-md-6:nth-last-child(1) .outbox,
.features_chg .col-md-6:nth-last-child(3) .outbox {
  margin-top: -60px;
}







.menu_icon {
  display: none;
  cursor: pointer;
  color: #fff;
}



.close_icon {
  text-align: left;
  padding: 10px;
  color: #797f89;
  font-size: 20px;
  display: none;
  cursor: pointer;
}



.buytoken {
  margin-top: -86px;
}

.buytokenbg {
  background: #000;
  padding-bottom: 150px;
  padding-top: 150px;
}

.buytoken .head {
  margin-bottom: 40px;
}

.buytoken .head p {
  color: #707883;
}

.buytoken .head_box {
  padding: 20px;
  background: linear-gradient(133deg, #070807 40%, #010102);
}

.buytoken .head_box h5 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3px;
}

.buytoken .head_box p {
  color: #676f79;
  font-size: 15px;
  margin-bottom: 0;
}

.buytoken .head_box p span {
  color: #2999b8;
}

.buytoken .box {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #2c303600;
  background: linear-gradient(#000, rgb(0, 0, 0)) padding-box, linear-gradient(45deg, #000000, #0d4e94 50%, #000, #000, #69b12a) border-box;
}

.buytoken .box .btnbdr {
  margin-bottom: 20px;
}

.buytoken .box .primary_btn {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 30px;
}

.buytoken .box_ctn {
  padding: 30px;
  padding-bottom: 20px;
}

.buytoken .box_ctn select {
  border: 1px solid #1a1d21;
  margin-bottom: 15px;
  padding: 10px;
  outline: 0;
  background-image: url("./assets/images/dropdown_arrow.png");
  background-repeat: no-repeat;
  background-position: 97% center;
  appearance: none;
  padding-right: 35px;
}

.buytoken .box_ctn .label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buytoken .box_ctn .label {
  font-size: 15px;
}

.buytoken .box_ctn .label span {
  color: #2999b8;
}

.buytoken .blw_box {
  text-align: center;
}

.buytoken .blw_box p {
  margin-bottom: 10px;
}

.buytoken .blw_box p span {
  color: #2999b8;
}

.buytoken .blw_box p a {
  color: #2999b8;
  text-decoration: underline;
}


.buytoken .form-control {
  background: #060608;
  color: #fff;
  font-size: 15px;
  border: 1px solid #1a1d21;
  height: 52px;
  border-right: 0;
}

.buytoken .form-control::placeholder {
  color: #fff;
  font-size: 15px;
}

.buytoken .form-control:focus {
  box-shadow: none;
}

.buytoken .input-group-text {
  cursor: pointer;
  background: #060608;
  font-size: 15px;
  color: #fff;
  border-color: #1a1d21;
  height: 52px;
  margin: auto;
  width: 67.9px;
}

.buytoken input::-webkit-inner-spin-button,
.buytoken input::-webkit-outer-spin-button {
  opacity: 0;
  -moz-opacity: 0;
}

.buytoken .number_wrapper {
  position: relative;
  width: 100%;
  display: block;
}
.buytoken .number_wrappertwo {
  position: relative;
  width: 100%;
  display: block;
}

.buytoken .number_wrapper:after,
.buytoken .number_wrapper:before {
  position: absolute;
  /* right: 81px; */
  pointer-events: none;
  background: transparent;
  z-index: 10;
  font-size: 11px;
  width: 20px;
  height: 20px;
  background: #060608;
  right: 77px;
}

.buytoken .number_wrappertwo:after,
.buytoken .number_wrappertwo:before {
  position: absolute;
  /* right: 81px; */
  pointer-events: none;
  background: transparent;
  z-index: 10;
  font-size: 11px;
  width: 20px;
  height: 18px;
  background: #060608;
  right: 77px;
}


.buytoken .number_wrapper:after {
  color: #676f79;
  content: "\25BC";
  top: 41%;
  top: 45%;

}

.buytoken .number_wrappertwo:after {
  color: #676f79;
  content: "\25BC";
  top: 41%;
  top: 26%;
}

.buytoken .number_wrapper:before,
.buytoken .number_wrapper:active::before,
.buytoken .number_wrapper:focus::before {
  color: #676f79;
  content: "\25B2";
  bottom: 41%;
}

.buytoken .number_wrappertwo:before,
.buytoken .number_wrappertwo:active::before,
.buytoken .number_wrappertwo:focus::before {
  color: #676f79;
  content: "\25B2";
  bottom: 67%;
}

.buytoken .receive span {
  color: #2999b8;
}

.curve {
  width: 100%;
  height: 283px;
  background: #0b0c0f;
  border-radius: 0 0 50% 50%;
  /* background: aliceblue; */
  margin-bottom: -132px;
  z-index: 1;
  position: relative;
}

.staking .main_title {
  margin-bottom: 14px;
  color: #69b12a;
  font-weight: 600;
  font-size: 51px;
}

.staking .head p {
  font-weight: 500;
  font-size: 33px;
  color: #ffffff;
}
.stakeline {
  font-weight: bold;
}

.staking .head p.addr {
  color: #fbea78;
}

.staking .row {
  justify-content: center;
}

.staking .box {
  width: 100%;
  margin: 15px 0;
}

.staking .head_box {
  display: flex;
  align-items: center;
  width: 100%;
}

.staking .head_box_flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.staking .box:hover {
  background: linear-gradient(132deg, #000000, #000000) padding-box, linear-gradient(#569022, #0c498a) border-box;
  border: 1px solid transparent;
}

.staking ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
}

.staking ul li {
  color: #676f79;
  font-size: 15px;
}

.staking .view {
  text-decoration: underline;
}

.staking ul li.active {
  color: #2999b8;
  border-bottom: 1px solid #2999b8;
  padding-bottom: 5px;
}

.staking .box_ctn .label img {
  width: 25px;
  height: 25px;
}

.stakingchg .box_ctn {
  padding-bottom: 0;
}

.stakingchg ul {
  display: block;
}

.stakingchg li {
  display: flex;
  justify-content: space-between;
  color: #2999b8 !important;
  margin: 10px 0;
}

.stakingchg li p {
  margin-bottom: 0;
}

.stakingchg li.btns {
  justify-content: flex-end;
}

.stakingchg li.btns button {
  width: unset;
  border-radius: 15px !important;
}

.stakingchg li.btns button+button {
  margin-left: 15px;
}

.stakingchg li .btnbdr {
  margin-bottom: 0 !important;
}

.stakingchg li p {
  color: #fff;
}

.stakingchg .accordion-item {
  background: #0000;
  border: 0;
}

.stakingchg .accordion-button {
  background: transparent !important;
  box-shadow: none;
  color: #fff;
}

.stakingchg .accordion-button::after {
  filter: invert(1) brightness(25.5);
}

.stakingchg .accordion-body p {
  color: #fff;
}

.stakemodal .btnflx {
  display: flex;
  justify-content: center;
}

.stakemodal .btnflx button {
  width: unset;
  padding: 6px 20px;
  font-size: 16px;
  margin: 0 5px;
}


.btnbdr {
  display: inline-block;
  border-radius: 50em;
  border: 1px solid #42834b;
}

.primary_btn {
  text-align: center;
  margin: 4px 2px;
  transition: 0.3s;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 39px;
  display: inline-block;
  position: relative;
  border-radius: 50em;
  border: 0;
  background: linear-gradient(89deg, #0d4d93, #2365006e);
  margin-left: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
  width: 101%;
  font-family: 'Figtree', sans-serif;
}

.primary_btn:hover {
  color: #ffffff;
  text-decoration: none !important;
  background: linear-gradient(89deg, #2365006e, #0d4d93);
}


.faq_section {
  background: url("../src/assets/images/faqbg.png") no-repeat center;
  background-size: 100% 120%;
  padding-top: 20px;
  padding-bottom: 0px;
}

.faq_section .head p {
  font-weight: 300;
}

.faq_bg {
  /* background:url("../src/assets/images/faqbg.png") no-repeat center;
  background-size: contain; */

}

.faq_section .head {
  max-width: 45%;
}

#faq_accodian .accordion-header .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  background: #00000000;
  color: #fff;
  padding: 20px 48px 20px 30px;
  padding-left: 65px;
}

#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;

}

#faq_accodian .accordion-item {
  position: relative;
  padding: 0px;
  background-color: #000;
  border: 0;
  margin-bottom: 15px;
  border-radius: 30px;
  transition: 0.2s all;
  overflow: hidden;
  z-index: 1;
  background-image: url("./assets/images/minus.png");
  background-repeat: no-repeat;
  background-position: 96% 28px;
}

#faq_accodian .accordion-item.collapsed {
  background-image: url("./assets/images/plus.png");
  background-repeat: no-repeat;
  background-position: 96% 23px;
}

#faq_accodian .accordion-item:not(.collapsed)::before {
  content: "";
  position: absolute;
  background: #093667;
  width: 30%;
  height: 50%;
  bottom: -20px;
  left: -30px;
  filter: blur(51px);
  display: block;
  z-index: -1;
}

#faq_accodian .accordion-item::before {
  display: none;
}

#faq_accodian .accordion-item:not(.collapsed)::after {
  content: "";
  position: absolute;
  background: #69b12a;
  width: 30%;
  height: 20%;
  top: -20px;
  right: -30px;
  filter: blur(81px);
  border-radius: 50%;
  z-index: -1;
}

#faq_accodian .accordion-item::after {
  width: 0;
}

#faq_accodian .accordion-item p {
  font-weight: 300;
  margin-bottom: 0;
}

#faq_accodian .accordion-button::after {
  display: none;
}

#faq_accodian .accordion-body {
  padding-top: 0;
  padding-left: 65px;
}

#faq_accodian .accordion-body p {
  margin-bottom: 15px;
  cursor: default;
  padding-left: 0px;
  color: #fff;
}

/* #faq_accodian .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/platinum/minus.png") no-repeat center;
  position: absolute;
  right: 20px;
}

#faq_accodian .accordion-button.collapsed::after {
  background: url("./assets/images/platinum/plus.png") no-repeat center;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 20px;
} */

.footer {
  background-color: #0a0a0a;
  padding: 50px 0 30px;
}

.footer_panel_top {
  border-bottom: 1px solid #2f2f2f;
  padding-bottom: 10px;
}

.footer_panel_top h2 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 18px;
}



.footer_panel_top .input-group {
  background: linear-gradient(to right, black, #070707) padding-box, linear-gradient(to right, #0b437f, #5fa027) border-box;
  border-radius: 50em;
  border: 1px solid transparent;
  padding: 5px;
}

.footer_panel_top .input-group input {
  background: transparent;
  border: 0px solid #1a1d21;
  color: #ffffff;
  font-size: 18px;
  border-radius: 10px;
  padding-left: 30px;
  box-shadow: none !important;
  margin-right: 10px;
  font-weight: 300;
}

.footer_panel_top .input-group input::placeholder {
  color: #414141;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Figtree', sans-serif;
}

.footer_panel_top .btnbdr {
  border-radius: 50em !important;
}

.footer_panel_top .input-group-text {
  border-radius: 50px !important;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Figtree', sans-serif;
}

.footer_panel_top a {
  font-size: 16px;
  color: #69b12a;
  white-space: normal;
  word-break: break-all;
}
p.footer_panel_link {
  margin-bottom: 10px;
}
.footer_social_links {
  display: flex;
  list-style: none;
  padding: 0;
}

.footer_social_links li {
  margin: 0 10px;
}

.footer_social_links li:nth-child(1) {
  margin-left: 0;
}

.footer_social_links li a {
  width: 35px;
  height: 35px;
  background: #1b1b1f;
  border: 1px solid #1b1b1f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a0a0a;
  font-size: 22px;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: linear-gradient(91deg, #69b12a, #0d4e94);
  color: #0a0a0a;
}

.footer_panel_bottom {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_list_links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.footer_list_links li {
  position: relative;
}

.footer_list_links li+li {
  margin-left: 30px;
}

.footer_list_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: -15px;
}

.footer_list_links li:last-child::after {
  display: none;
}

.footer_list_links li a {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Figtree', sans-serif;
}

.footer_list_links li a:hover {
  text-decoration: none;
  color: #ffffff;
}

.footer_panel_bottom p {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 300;
  font-family: 'Figtree', sans-serif;
}


/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(0deg, rgb(5 5 5) 0%, rgb(0 0 0) 100%);
  background: linear-gradient(0deg, rgb(11 12 18) 0%, rgb(0 0 0) 100%);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #040f1a;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #040f1a;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.primary_modal .btn-close {
  filter: invert(0%) sepia(2%) saturate(57%) hue-rotate(294deg) brightness(88%) contrast(61%);
}

.connect_wallet_button:hover {
  border-color: #0a233c;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
  background: url("../src/assets/images/banner_bg.png") no-repeat center;
}

.title_flex {
  display: flex;
  text-align: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.buy_token_box {
  border: 1px solid #006a99;
  background: rgb(0, 31, 51);
  background: linear-gradient(0deg, rgba(0, 31, 51, 1) 0%, rgba(0, 46, 71, 1) 100%);
  border-radius: 30px;
  padding: 30px;
  position: relative;
}

.buy_token_box_inner,
.buy_token_box_outer {
  height: 100%;
  position: relative;
}

.buy_token_box_inner::before,
.buy_token_box_inner::after,
.buy_token_box_outer::before,
.buy_token_box_outer::after {
  content: "";
  background: url("../src/assets/images/presale_box_corner.png");
  width: 45px;
  height: 48px;
  position: absolute;
}

.buy_token_box_inner::before {
  top: -32px;
  left: -33px;
}

.buy_token_box_inner::after {
  top: -35px;
  right: -30px;
  transform: rotate(90deg);
}

.buy_token_box_outer::before {
  bottom: -34px;
  left: -30px;
  transform: rotate(-90deg);
}

.buy_token_box_outer::after {
  bottom: -32px;
  right: -33px;
  transform: rotate(180deg);
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */

.buy_token_box form input.form-control {
  background: #001625;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  box-shadow: none !important;
}

.buy_token_box form select.form-select {
  background: #001625 url("../src/assets/images/caret-down-fill.png") no-repeat right .75rem center;
  background-size: 13px 9px;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  box-shadow: none !important;
}

.buy_token_box form label {
  color: #fff;
}

.custom_table .rdt_TableHead,
.custom_table .rdt_TableHeadRow,
.custom_table .rdt_Table>div {
  background-color: #00101a !important;
  color: #fff !important;
}

.custom_table .rdt_TableRow,
.custom_table .rdt_Pagination {
  color: #ffffff;
  background-color: #001624;
}

.custom_table .rdt_Pagination button {
  color: rgb(255 255 255 / 54%);
  fill: rgb(255 255 255 / 54%);
}

.custom_table .rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%);
  fill: rgb(255 255 255 / 18%);
}

ul.dropdown-menu.walletdrop {
  /* background-color: rgb(0, 53, 44); */
  background: linear-gradient(89deg, #2365006e, #0d4d93);
}

ul.dropdown-menu.walletdrop li a {
  color: #fff;
  font-size: 14px;
}

/* 
ul.dropdown-menu.walletdrop li a:hover {
  background: #0f311500;
} */
ul.dropdown-menu.walletdrop li a:hover {
  background: #094162;
}


ul.dropdown-menu.walletdrop li a.active::after,
ul.dropdown-menu.walletdrop li a:hover::after {
  display: none;
}

.errortext {
  color: red;
}

.form-check {
  margin-top: 10px;
  margin-left: 8px;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

input.admininput.form-control {
  margin-top: 16px;
}

button.claim.primary_btn {
  margin: auto;
  width: 125px;
  display: flex;
  justify-content: center;
}

/* Media Queries */


@media screen and (max-width: 1399px) {

  .features_chg .col-md-6:nth-last-child(1) .outbox,
  .features_chg .col-md-6:nth-last-child(3) .outbox {
    margin-top: -36px;
  }
}


@media screen and (max-width: 1199px) {
  .main_title {
    font-size: 36px;
  }

  .h2tag {
    font-size: 37px;
  }

  .page_header {
    margin-top: 0;
  }

  .presale_box h2 {
    font-size: 26px;
  }

  .presale_box::before {
    width: 48%;
  }

  .main_navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }

  .navbar_right li a.active::after,
  .navbar_right li a:hover::after {
    display: none;
  }


  .buytoken {
    margin-top: 0;
  }

  .buytokenbg {
    border-radius: 0 !important;
  }


  .banner .paratag {
    max-width: 81%;
  }

  .banner h1 {
    font-size: 50px;
  }

  .buy_token .paratag {
    max-width: 100%;
  }

  .features_chg .col-md-6:nth-last-child(1) .outbox,
  .features_chg .col-md-6:nth-last-child(3) .outbox {
    margin-top: -66px;
  }

  .timer_time {
    font-size: 33px;
  }

  .timer_panel .num {
    background-size: contain !important;
    width: 199px;
    height: 97px;
  }

  .grid_sec .head {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {

  .main_navbar {
    background-color: #050505 !important;
  }



  .h5tag {
    font-size: 20px;
  }


  .menu_show {
    display: block !important;
    transition: .5s;
    right: 0;
  }

  .main_navbar {
    padding: 10px 0;
  }

  .main_navbar .navbar-brand {
    display: block;
    padding-left: 30px;
  }



  .menu_icon,
  .close_icon {
    display: block;
  }

  .menu_icon {
    font-size: 23px;
  }


  .mar_top_section {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 30px;
  }


  .footer_panel_top .input-group {
    width: 100%;
  }

  .page_header h1 {
    margin: 15px 0 30px;
  }

  .page_header {
    text-align: center;
  }

  .timer_panel {
    justify-content: center;
  }


  .faq_section .head {
    max-width: 100%;
  }

  .features_chg .col-md-6:nth-last-child(1) .outbox,
  .features_chg .col-md-6:nth-last-child(3) .outbox {
    margin-top: -92px;
  }

  .footer_panel_bottom {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .head {
    max-width: 100%;
  }

  .h2tag {
    font-size: 25px;
  }

  .menu_icon {
    padding-right: 0;
  }

  .primary_btn:after,
  .primary_btn:before {
    display: none;
  }

  .main_navbar,
  .main_navbar.fixed_navbar {
    background-color: #0a0c13 !important;
  }

  .main_navbar .navbar-brand {
    padding-left: 0px;
  }

  .page_header {
    overflow: hidden !important;
    padding: 150px 0 100px;
  }

  .page_header::after {
    display: none;
  }

  .page_header::before {
    width: 94px;
    height: 94px;
  }



  .footer_panel_bottom {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .footer_list_links {
    margin-bottom: 10px;
  }

  .footer_panel_top .input-group-text {
    padding: 10px 18px;
  }


  .footer_panel_top h2 {
    font-size: 24px;
  }

  #faq_accodian .accordion-header .accordion-button {
    font-size: 18px;
  }

  #faq_accodian .accordion-body p {
    font-size: 14px;
  }

  .main_title {
    font-size: 30px;
  }

  .inner_para {
    font-size: 16px;
  }

  .page_header h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .token_dis_div h2 {
    font-size: 24px;
  }

  .primary_btn {
    font-size: 16px;
    padding: 12px 40px;
  }

  .primary_btn:after,
  .primary_btn:before {
    content: " ";
    width: 30px;
    height: 30px;
  }

  .buytoken .box .primary_btn {
    font-size: 16px;
  }


  .curve {
    height: 183px;
    border-radius: 0;
  }

  .presale_box {
    margin-top: 0;
  }

  .timer_time {
    font-size: 28px;
    line-height: 30px;
  }

  .timer_panel>span {
    margin: 5px -15px 0 0;
  }

  .presale_box::before {
    width: 72%;
  }

  .features_chg {
    background-size: cover;
  }

  .features_chg .col-md-6:nth-last-child(1) .outbox,
  .features_chg .col-md-6:nth-last-child(3) .outbox {
    margin-top: 30px;
  }

  .features_chg .col-md-6:nth-last-child(1) .outbox .box,
  .features_chg .col-md-6:nth-child(1) .outbox .box {
    padding-top: 50px;
  }

  .footer_panel_top {
    padding-bottom: 15px;
    text-align: center;
  }

  .footer_social_links {
    justify-content: center;
  }

  .featureschg2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .featureschg2 .mar {
    margin-top: unset;
  }

  .featureschg2 .slick-arrow {
    top: 0;
    width: 55px;
    height: 55px;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .featureschg2 .slick-prev {
    left: -80px;
  }

  .featureschg2 .slick-next {
    right: -80px;
  }

  .featureschg2 .slick-prev::after,
  .featureschg2 .slick-next::after {
    height: 95px;
  }
}

@media screen and (max-width: 575px) {
  .faq_section {
    border-radius: 0;
  }

  .presale_box {
    padding: 30px;
  }

  .navbar_right .btnbdr {
    margin-top: 15px;
  }

  .navbar_right button.primary_btn {
    margin-top: 15px;
  }

  .rotating_bonjour_logo {
    width: 150px;
  }

  .token_details>div {
    width: 100%;
    text-align: center;
  }

  .buytoken .box_ctn .label {
    flex-direction: column;
    align-items: flex-start;
  }

  .buytoken .box_ctn .label p {
    margin-bottom: 10px;
  }

  .banner .paratag {
    max-width: 100%;
  }

  .presale_box h2 {
    position: unset;
  }

  .presale_box::before {
    display: none;
  }

  .timer_time {
    font-size: 24px;
  }

  .features .box h5 {
    font-size: 20px;
  }

  #faq_accodian .accordion-header .accordion-button,
  #faq_accodian .accordion-body {
    padding-left: 35px;
  }
  .table_sec ul{
    flex-direction: column;
    align-items: center;
  }
  .table_sec ul li{
    width: 100%;
  }
  .footer_panel_left p label {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .timer_panel>span {
    margin: 5px -8px 0 0;
  }

  .presale_box {
    padding: 13px;
  }

  .timer_time {
    font-size: 20px;
    line-height: 19px;
  }

  span.timer_label {
    font-size: 10px;
  }

  .footer_list_links {
    flex-direction: column;
  }

  .footer_list_links li+li {
    margin-left: 0;
  }

  .footer_list_links li::after {
    display: none;
  }
}